<template>
  <v-container class="d-flex flex-column">
    <div class="flex-fill">
      <v-container>
        <v-row class="mr-2">
          <template v-for="(item, index) in serverData?.sales">
            <v-col :key="index" cols="12" lg="8">
              <v-row align="center" justify-lg="space-between">
                <v-col cols="12" md="4" class="pt-5">
                  <p class="font-size-lg font-weight-medium">
                    {{ $t(item.text) }}
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                  :class="[
                    'd-flex justify-content-end h-75px',
                    item.field_type === 'select' ? ' pt-5' : '',
                  ]"
                >
                  <v-switch
                    class="h-55px"
                    v-model="formData[index]['value']"
                    inset
                    dense
                  ></v-switch>
                </v-col>
                <v-col cols="12">
                  <div class="separator separator-dashed p-0"></div>
                </v-col>
              </v-row>
            </v-col>
          </template>
        </v-row>
      </v-container>
    </div>

    <v-row v-if="serverData">
      <v-col cols="12" lg="8">
        <v-row class="d-flex justify-content-end mt-15">
          <v-col cols="auto">
            <button
              @click="submit"
              type="submit"
              class="btn btn-info px-5 py-3"
            >
              {{ this.$t("submit") }}
            </button>
          </v-col>
        </v-row></v-col
      ></v-row
    >
  </v-container>
</template>

<script>
import ApiService from "@/core/services/api.service";
import SwalService from "@/core/services/swal.service";

export default {
  name: "SalesFormFields",

  props: ["pageLoader", "actionItem"],

  data: () => ({
    serverData: null,
    formData: [],
  }),
  methods: {
    loadServerData() {
      if (!this.serverData) {
        this.pageLoader(true);
        let payload = {
          tab_name: "sales",
        };

        if (this.actionItem) {
          payload.id = this.actionItem.id;
        }

        return ApiService.post("authorizations/clients/edit", payload)
          .then(({ data }) => {
            this.serverData = data;

            const actives = data.customer.sales;
            this.formData = data.sales.map((item) => ({
              index: item.index,
              value: actives.includes(item.index) ? 1 : 0,
            }));

            // this.initializeFormData(data?.customer);
          })
          .finally(() => {
            this.pageLoader(false);
          });
      }
    },
    getSelectOptions(field_values_key) {
      if (!this.serverData) return [];
      return this.serverData[field_values_key] || [];
    },
    submit() {
      this.pageLoader(true);
      // const sales = this.serverData?.sales.map((item) => item.index));
      const sales = [];
      this.formData.forEach((item) => item.value && sales.push(item.index));
      const payload = {
        tab_name: "sales",
        id: this.actionItem.id,
        sales,
      };

      ApiService.post("authorizations/clients/update", payload)
        .then(() => {
          SwalService.successMessage({
            title: this.$t(this.$t("updated")),
            html: this.$t("updated_successfully"),
          });
          this.toggleModal();
          this.refresher();
        })
        .finally(() => {
          this.pageLoader(false);
        });
    },
    reset() {
      this.serverData = null;
    },
  },
  // created() {
  //   this.loadServerData();
  // },
};
</script>
