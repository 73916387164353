<template>
  <v-container class="d-flex flex-column flex-fill ml-2 mr-4">
    <v-row>
      <v-col cols="12" lg="8">
        <FieldContainer
          v-if="serverData.organizations"
          :label="$t('organization')"
          :divider="true"
        >
          <v-autocomplete
            v-model="formData.organization_id"
            clearable
            dense
            outlined
            class="rounded-lg h-50px"
            required
            item-text="text"
            item-value="index"
            :items="serverData.organizations"
            :error-messages="organizationsErrors"
            @blur="$v.formData.organization_id.$touch()"
            @input="$v.formData.organization_id.$touch()"
          ></v-autocomplete>
        </FieldContainer>

        <FieldContainer :label="$t('division')" :divider="true">
          <v-autocomplete
            v-model="formData.division_id"
            clearable
            dense
            outlined
            class="rounded-lg h-50px"
            required
            item-text="text"
            item-value="index"
            :items="divisions"
          ></v-autocomplete>
        </FieldContainer>

        <FieldContainer :label="$t('client_type')" :divider="true">
          <v-autocomplete
            v-model="formData.type_id"
            clearable
            dense
            outlined
            class="rounded-lg h-50px"
            required
            :items="serverData.client_types"
            item-text="text"
            item-value="index"
            :error-messages="typeErrors"
            @blur="$v.formData.type_id.$touch()"
            @input="$v.formData.type_id.$touch()"
          />
        </FieldContainer>

        <FieldContainer
          :label="$t('aggregator')"
          :divider="true"
          v-if="formData.type_id == 1"
        >
          <v-autocomplete
            dense
            outlined
            class="rounded-lg h-50px"
            clearable
            v-model="formData.aggregator_id"
            item-text="text"
            item-value="index"
            :items="serverData.aggregators"
            :error-messages="aggregatorErrors"
            @blur="$v.formData.aggregator_id.$touch()"
            @input="$v.formData.aggregator_id.$touch()"
          />
        </FieldContainer>

        <FieldContainer :label="$t('name')" :divider="true">
          <v-text-field
            dense
            outlined
            class="rounded-lg h-50px"
            clearable
            v-model="formData.name"
            :error-messages="nameErrors"
            @blur="$v.formData.name.$touch()"
            @input="$v.formData.name.$touch()"
          />
        </FieldContainer>

        <FieldContainer :label="$t('system_code')" :divider="true">
          <v-text-field
            dense
            outlined
            class="rounded-lg h-50px"
            clearable
            v-model="formData.systemCode"
            :error-messages="systemCodeErrors"
            @blur="$v.formData.systemCode.$touch()"
            @input="$v.formData.systemCode.$touch()"
          ></v-text-field>
        </FieldContainer>

        <FieldContainer :label="$t('email')" :divider="true">
          <v-text-field
            dense
            outlined
            class="rounded-lg h-50px"
            clearable
            v-model="formData.email"
            :error-messages="emailErrors"
            @blur="$v.formData.email.$touch()"
            @input="$v.formData.email.$touch()"
          />
        </FieldContainer>

        <FieldContainer :label="$t('staff_account_limit')" :divider="true">
          <v-text-field
            dense
            outlined
            class="rounded-lg h-50px"
            clearable
            v-model="formData.staff_account_limit"
            :error-messages="stuffAccountLimitErrors"
            @blur="$v.formData.staff_account_limit.$touch()"
            @input="$v.formData.staff_account_limit.$touch()"
          />
        </FieldContainer>

        <FieldContainer :label="$t('shipping_label')" :divider="true">
          <v-text-field
            v-model="formData.shipping_label_name"
            dense
            class="rounded-lg h-50px"
            required
            persistent-hint
            outlined
            clearable
          ></v-text-field>
        </FieldContainer>

        <FieldContainer :label="$t('photo')" :divider="true">
          <v-file-input
            v-model="formData.photo"
            show-size
            counter
            multiple
            dense
            outlined
            class="rounded-lg h-50px"
            clearable
            accept="image/png, image/jpeg, image/bmp"
            append-icon="mdi-camera"
            prepend-icon=""
          />
        </FieldContainer>

        <FieldContainer :label="$t('logo')" :divider="true">
          <v-file-input
            v-model="formData.logo"
            show-size
            counter
            multiple
            dense
            outlined
            class="rounded-lg h-50px"
            clearable
            accept="image/png, image/jpeg, image/bmp"
            append-icon="mdi-camera"
            prepend-icon=""
          />
        </FieldContainer>

        <FieldContainer :label="$t('description')" :divider="false">
          <v-text-field
            dense
            outlined
            class="rounded-lg h-50px"
            clearable
            v-model="formData.description"
          />
        </FieldContainer>
      </v-col>
    </v-row>

    <v-row class="mt-10">
      <v-col cols="12" lg="8" class="pr-0">
        <v-row class="d-flex justify-content-end">
          <v-col cols="auto">
            <button
              @click="submit"
              type="submit"
              class="btn btn-info px-5 py-3"
            >
              {{ this.$t("submit") }}
            </button>
          </v-col>
        </v-row></v-col
      ></v-row
    >
  </v-container>
</template>

<script>
import { required, email, numeric, requiredIf } from "vuelidate/lib/validators";

import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";

import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
import validationMixin from "@/own/mixins/validationMixin.js";
import ApiService from "@/core/services/api.service";
import SwalService from "@/core/services/swal.service";
import FieldContainer from "@/own/components/customers/clients/FormParts/FieldContainer.vue";

export default {
  name: "BasicFormFields",
  mixins: [validationMixin, fieldValueValidation],
  components: { FieldContainer },
  props: ["serverData", "pageLoader", "actionItem", "refresher"],
  validations: {
    formData: {
      systemCode: { required },
      name: { required },
      email: { required, email },
      staff_account_limit: { required, numeric },
      type_id: { required },
      aggregator_id: {
        required: requiredIf(function () {
          this.formData.type_id === 1;
        }),
      },
      organization_id: {
        required: requiredIf(function () {
          return !!this.serverData.organizations;
        }),
      },
      // photo: { required },
    },
  },
  data: () => ({
    formData: {
      division_id: null,
      type_id: null,
      aggregator_id: null,
      organization_id: null,
      name: null,
      systemCode: null,
      email: null,
      shipping_label_name: null,
      photo: null,
      logo: null,
      description: null,
    },
  }),
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.pageLoader(true);

        let url = `/authorizations/clients/${
          this.actionItem ? `update` : `store`
        }`;
        let data = new FormData();
        Object.entries(this.formData).forEach((item) => {
          if (
            (item[1] &&
              item[0] !== "image" &&
              item[0] !== "photo" &&
              item[0] !== "logo") ||
            item[1] === 0
          ) {
            if (Array.isArray(item[1])) {
              item[1].forEach((it) => data.append(`${item[0]}[]`, it));
            } else {
              data.append(item[0], item[1]);
            }
          } else if (
            item[1] &&
            (item[0] === "image" || item[0] === "photo" || item[0] === "logo")
          ) {
            data.append(item[0], item[1][0]);
          }
        });

        if (this.actionItem) {
          data.append("id", this.actionItem.id);
          data.append("tab_name", "basic");
        }

        ApiService.post(url, data)
          .then(({ data }) => {
            if (data.customer) {
              this.$store.commit(SET_ITEM_FOR_ACTION, data.customer);
            }
            const title = this.actionItem
              ? this.$t("updated")
              : this.$t("created");
            SwalService.successMessage({
              title: title,
              html: this.$t("submitted_successfully"),
            });
            this.refresher();
          })
          .finally(() => {
            this.pageLoader(false);
          });
      }
    },
    getFormData() {
      const {
        type_id,
        systemCode,
        staff_account_limit,
        spl_integration_type,
        name,
        email,
        division_id,
        description,
        aggregator_id,
        shipping_label_name,
        organization_id,
      } = this.serverData.customer;

      this.formData = {
        ...this.formData,
        type_id,
        systemCode,
        staff_account_limit,
        spl_integration_type,
        name,
        email,
        division_id,
        description,
        aggregator_id,
        shipping_label_name,
        organization_id,
      };
    },
    reset() {
      this.$v.$reset();
      this.formData = {
        division_id: null,
        type_id: null,
        aggregator_id: null,
        organization_id: null,
        name: null,
        systemCode: null,
        email: null,
        shipping_label_name: null,
        photo: null,
        logo: null,
        description: null,
      };
    },
  },
  computed: {
    typeErrors() {
      return this.handleFormValidation("type_id", this);
    },
    organizationsErrors() {
      return this.handleFormValidation("organization_id", this);
    },
    nameErrors() {
      return this.handleFormValidation("name", this);
    },
    systemCodeErrors() {
      return this.handleFormValidation("systemCode", this);
    },
    emailErrors() {
      return this.handleFormValidation("email", this);
    },
    aggregatorErrors() {
      return this.handleFormValidation("aggregator_id", this);
    },
    stuffAccountLimitErrors() {
      return this.handleFormValidation("staff_account_limit", this);
    },
    divisions() {
      if (this.serverData.organizations) {
        return this.serverData.organizations.find(
          (item) => item.index === this.formData.organization_id
        )?.divisions;
      }
      // else if (this.serverData.divisions) {
      //   return this.serverData.divisions;
      // }
      else return [];
    },
    // photoErrors() {
    //   return this.handleFormValidation("photo", this);
    // },
  },
};
</script>

<style></style>
